import {createContext, useContext, useEffect, useState} from 'react'

import {TContext, TProvider} from './ViewportContextTypes'

const ViewportContext = createContext<TContext>({})

export const ViewportProvider = ({children}: TProvider): JSX.Element => {
  const [storybookIframe, setStoryboardIframe] = useState<HTMLIFrameElement | null>(null)

  let initialWidth, initialHeight
  if (typeof window !== 'undefined') {
    initialWidth = window.innerWidth
    initialHeight = window.innerHeight
  }

  const [width, setWidth] = useState(initialWidth)
  const [height, setHeight] = useState(initialHeight)

  const handleWindowResize = () => {
    window.requestAnimationFrame(() => {
      if (!storybookIframe) {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
      } else {
        setWidth(storybookIframe.offsetWidth)
        setHeight(storybookIframe.offsetHeight)
      }
    })
  }

  useEffect(() => {
    setStoryboardIframe(
      document.querySelector('#storybook-preview-iframe') as HTMLIFrameElement | null
    )

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return <ViewportContext.Provider value={{width, height}}>{children}</ViewportContext.Provider>
}

export const useViewport = (): TContext => {
  const {width, height} = useContext(ViewportContext)
  return {width, height}
}
