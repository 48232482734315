import '@/styles/globals.scss'
import '/node_modules/flag-icons/css/flag-icons.min.css'

import type {AppProps} from 'next/app'
import Script from 'next/script'

import {ViewportProvider} from '@/hooks/ViewportContext'

function MyApp({Component, pageProps}: AppProps): JSX.Element {
  return (
    <ViewportProvider>
      {/* <SplashScreen /> */}
      <Component {...pageProps} />
      {process.env.NEXT_PUBLIC_GTM_ID ? (
        <Script id='google-tag-manager' strategy='afterInteractive'>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
        </Script>
      ) : null}
    </ViewportProvider>
  )
}

export default MyApp
